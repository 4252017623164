import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from './pages/index';
import News from './pages/News';
import Faq from './pages/Faq';

import Fund from './pages/wallet/Fund';
import Wallet from './pages/wallet/Wallet';
import Loan from './pages/wallet/Loan';
import OnlineLoan from './pages/wallet/OnlineLoan';
import DownlineList from './pages/referral/DownlineList';


import Trade from './pages/wallet/Trade';
import OnlineTrade from './pages/wallet/OnlineTrade';


import Price from './pages/price';
import Demo from './pages/demo';
import Intro from './pages/intro';
import Lock from './pages/lock';
import Otp1 from './pages/otp-1';
import Otp2 from './pages/otp-2';
import PriceDetails from './pages/price-details';
import Profile from './pages/profile';
import SettingsActivity from './pages/settings-activity';
import SettingsApi from './pages/settings-api';
import SettingsApplication from './pages/settings-application';
import SettingsFees from './pages/settings-fees';

import SettingsPaymentMethod from './pages/profile/settings-payment-method';
import SettingsPrivacy from './pages/settings-privacy';
import SettingsProfile from './pages/profile/settings-profile';
import SettingsKYC from './pages/profile/settings-kyc';
import SettingsSalary from './pages/profile/settings-salary';


import SettingsChangePassword from './pages/settings/settings-change-password';
import SettingsChangePin from './pages/settings/settings-change-pin';
import SettingsUpdateGoogleAuth from './pages/settings/settings-google-auth';


import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import UserVerification from './pages/auth/UserVerification';
import Reset from './pages/auth/Reset';

import UnverifiedMailRoute from '../utility/UnverifiedMailRoute';
import PrivateRoute from '../utility/PrivateRoute';
import PublicRoute from '../utility/PublicRoute';

import { allRemoteSettingsSelect } from '../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../redux/actions';

import Loader from '../utility/customComponents/loader/index';


const Router = () => {

    const site_settings = useSelector(allRemoteSettingsSelect);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("system/refresh", 10, {}, response => {
            // setLoading(false)
            setLoading(false);
        }))
    }, [])

    return (
        <>

            {loading && <Loader />}

            {!loading && <>
                
                <BrowserRouter>
                    <div id="main-wrapper">
                        <Switch>
                            <PrivateRoute path='/' exact component={Dashboard} />
                            <PrivateRoute path='/news' exact component={News} />
                            <PrivateRoute path='/faq' exact component={Faq} />


                            <PrivateRoute path='/fund' component={Fund} />
                            <PrivateRoute path='/wallet' component={Wallet} />

                            <PrivateRoute path='/loan' component={Loan} />
                            <PrivateRoute path='/loan-online' component={OnlineLoan} />
                            <PrivateRoute path='/referral' component={DownlineList} />

                            <PrivateRoute path='/trade' component={Trade} />
                            <PrivateRoute path='/trade-online' component={OnlineTrade} />



                            {/*<PrivateRoute path='/price' component={Price} />
                            <PrivateRoute path='/intro' component={Intro} />
                            <PrivateRoute path='/demo' component={Demo} />
                            
                            <PrivateRoute path='/lock' component={Lock} />
                            <PrivateRoute path='/otp-1' component={Otp1} />
                            <PrivateRoute path='/otp-2' component={Otp2} />
                            <PrivateRoute path='/price-details' component={PriceDetails} />
                            <PrivateRoute path='/profile' component={Profile} />
                            <PrivateRoute path='/settings-api' component={SettingsApi} />
                            <PrivateRoute path='/settings-application' component={SettingsApplication} />
                            <PrivateRoute path='/settings-fees' component={SettingsFees} />*/}

                            <PrivateRoute path='/settings-activity' component={SettingsActivity} />
                            <PrivateRoute path='/settings-payment-method' component={SettingsPaymentMethod} />
                            <PrivateRoute path='/settings-privacy' component={SettingsPrivacy} />
                            <PrivateRoute path='/settings-profile' component={SettingsProfile} />
                            <PrivateRoute path='/settings-kyc' component={SettingsKYC} />
                            <PrivateRoute path='/settings-salary' component={SettingsSalary} />


                             
                            <PrivateRoute path='/settings-password' component={SettingsChangePassword} />
                            <PrivateRoute path='/settings-pin' component={SettingsChangePin} />
                            <PrivateRoute path='/settings-google-auth' component={SettingsUpdateGoogleAuth} />

                            <UnverifiedMailRoute path='/verify-user' component={UserVerification} />
                            <PublicRoute path='/signin' component={Signin} />
                            <PublicRoute path='/signup' component={Signup} />
                            <PublicRoute path='/reset' component={Reset} />

                        </Switch>
                    </div>
                </BrowserRouter>
            </>}
        </>
    );
};

export default Router;